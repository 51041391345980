<template>
  <Layout>
    <section class="my-3">
      <div class="row">
        <div class="col-lg-4 col-12 editing-user">
          <UserVerticalCard :user="currentUser" :isEditMode="isEditMode" />
          <AvatarCropper
            trigger="#user-vertical-cart-avatar"
            :upload-url="$options.avatarUploadEndpoint"
            upload-form-name="image"
            :labels="{ submit: 'Enviar', cancel: 'Cancelar' }"
            :output-options="{ width: 512, height: 512 }"
            :cropper-options="{
              aspectRatio: 1,
              autoCropArea: 1,
              viewMode: 1,
              movable: false,
              zoomable: false,
            }"
            @uploaded="avatarUploaded"
          />
        </div>
        <div class="col-lg-8 col-12">
          <form @submit.prevent="onSubmit">
            <div class="card">
              <div class="card-body">
                <h4 class="mb-4">Identificação</h4>

                <div class="row">
                  <div class="col-lg-6 col md-6 col-12">
                    <b-form-group label="Nome" label-for="name">
                      <b-form-input
                        id="name"
                        type="text"
                        v-model="form.name"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-lg-6 col md-6 col-12">
                    <b-form-group label="Sobrenome" label-for="sobrenome">
                      <b-form-input
                        id="sobrenome"
                        type="text"
                        v-model="form.surname"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="my-2">
                  <label for="editor">Biografia</label>
                  <b-form-textarea
                    id="textarea"
                    v-model="form.bio"
                    rows="3"
                    max-rows="6"
                  />
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h4 class="mb-3">Dados Pessoais Sensíveis</h4>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-12">
                    <b-form-group
                      label="Data de nascimento"
                      label-for="birthdate"
                    >
                      <input
                        id="birthdate"
                        v-model="form.birthDate"
                        v-mask="'##/##/####'"
                        type="text"
                        class="form-control"
                      />
                    </b-form-group>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group mb-4">
                      <label for="telefone">Telefone</label>
                      <input
                        v-mask="'(##) #####-####'"
                        type="text"
                        class="form-control"
                        id="telefone"
                        v-model="form.telephone"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <h5>Meus interesses:</h5>
                  <div
                    v-for="(interest, i) in showInterests"
                    :key="i"
                    class="d-inline-flex"
                  >
                    <span
                      class="badge badge-info mr-1 mb-1"
                      style="font-size: 16px !important;"
                    >
                      {{ interest }}
                    </span>
                  </div>
                </div>

                <div class="custom-control custom-checkbox mb-2">
                  <input
                    id="hideSensitiveData"
                    type="checkbox"
                    class="custom-control-input"
                    v-model="form.hideSensitiveData"
                  />
                  <label class="custom-control-label" for="hideSensitiveData">
                    Manter privados os dados pessoais sensíveis.
                  </label>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <h4 class="mb-3">Permissões</h4>

                <div class="custom-control custom-checkbox mb-2">
                  <input
                    id="participateInRankings"
                    type="checkbox"
                    class="custom-control-input"
                    v-model="form.participateInRankings"
                  />
                  <label
                    class="custom-control-label"
                    for="participateInRankings"
                  >
                    Você deseja participar dos rankings?
                  </label>
                </div>

                <div class="custom-control custom-checkbox mb-2">
                  <input
                    id="hideStats"
                    type="checkbox"
                    class="custom-control-input"
                    v-model="form.hideStats"
                  />
                  <label class="custom-control-label" for="hideStats">
                    Você deseja ocultar para si as suas estatísticas?
                  </label>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <h4 class="mb-3">Redes sociais</h4>
                <b-form-group label="Facebook" label-for="facebook">
                  <b-form-input
                    id="facebook"
                    v-model="form.facebook"
                    placeholder="Apenas seu nome de usuário."
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Instagram" label-for="instagram">
                  <b-form-input
                    id="instagram"
                    v-model="form.instagram"
                    placeholder="Apenas seu nome de usuário."
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Twitter" label-for="twitter">
                  <b-form-input
                    id="twitter"
                    v-model="form.twitter"
                    placeholder="Apenas seu nome de usuário."
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="LinkedIn" label-for="linkedin">
                  <b-form-input
                    id="linkedin"
                    v-model="form.linkedin"
                    placeholder="Apenas seu nome de usuário."
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h4 class="mb-3">Alterar Senha</h4>
                <b-form-group label="Nova senha" label-for="password">
                  <b-form-input
                    id="password"
                    type="password"
                    v-model="form.password"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Confirmar nova senha"
                  label-for="cpassword"
                >
                  <b-form-input
                    id="cpassword"
                    type="password"
                    v-model="form.confirmPassword"
                    :required="Boolean(form.password.length)"
                    :class="[
                      {
                        'is-invalid':
                          form.confirmPassword.length > 0 &&
                          !$v.form.confirmPassword.sameAsPassword,
                      },
                      {
                        'is-valid':
                          form.confirmPassword.length > 0 &&
                          $v.form.confirmPassword.sameAsPassword,
                      },
                    ]"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-between my-2">
                  <button
                    type="reset"
                    @click.prevent="resetForm"
                    class="btn btn-secondary"
                  >
                    Cancelar
                  </button>
                  <button type="submit" class="btn btn-success">
                    <i class="mdi mdi-check-bold"></i>
                    Confimar alterações
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </Layout>
</template>

<script>
import { mapState } from "vuex";
import { authMethods } from "@/state/helpers";
import * as api from "@/api";
import Layout from "../layouts/main";
import UserVerticalCard from "@/components/UserVerticalCard.vue";
import AvatarCropper from "vue-avatar-cropper";

import { sameAs } from "vuelidate/lib/validators";

const formDefaults = () => ({
  name: "",
  surname: "",
  birthDate: "",
  telephone: "",
  password: "",
  confirmPassword: "",
  profession: "",
  bio: "",
  facebook: "",
  instagram: "",
  twitter: "",
  linkedin: "",
  hideSensitiveData: true,
  hideStats: false,
  participateInRankings: true,
});

export default {
  name: "editProfile",
  components: {
    Layout,
    UserVerticalCard,
    AvatarCropper,
  },

  avatarUploadEndpoint: `${api.getBaseUrl()}/upload?width=512&height=512`,

  data() {
    return {
      form: formDefaults(),
    };
  },

  validations: {
    form: {
      confirmPassword: { sameAsPassword: sameAs("password") },
    },
  },

  computed: {
    ...mapState("auth", ["currentUser"]),

    isEditMode() {
      return true;
    },

    showInterests() {
      let interests = this.currentUser.interests.map(
        (interest) => interest.name
      );
      return interests;
    },
  },

  created() {
    this.resetForm();
    this.form.name = this.currentUser.name;
    this.form.surname = this.currentUser.surname;
    this.form.birthDate = this.currentUser.birthDate
      ? this.currentUser.birthDate.split("-").reverse().join("/")
      : "";
    this.form.telephone = this.currentUser.telephone;
    this.form.password = "";
    this.form.confirmPassword = "";
    this.form.profession = this.currentUser.profession;
    this.form.bio = this.currentUser.bio;
    this.form.facebook = this.currentUser.facebook;
    this.form.instagram = this.currentUser.instagram;
    this.form.twitter = this.currentUser.twitter;
    this.form.linkedin = this.currentUser.linkedin;
    this.form.hideSensitiveData = this.currentUser.hideSensitiveData;
    this.form.hideStats = this.currentUser.hideStats;
    this.form.participateInRankings = this.currentUser.participateInRankings;
  },

  methods: {
    ...authMethods,

    resetForm() {
      this.form = formDefaults();
      this.form.name = this.currentUser.name;
      this.form.surname = this.currentUser.surname;
      this.form.birthDate = this.currentUser.birthDate
        ? this.currentUser.birthDate.split("-").reverse().join("/")
        : "";
      this.form.telephone = this.currentUser.telephone;
      this.form.password = "";
      this.form.confirmPassword = "";
      this.form.profession = this.currentUser.profession;
      this.form.bio = this.currentUser.bio;
      this.form.facebook = this.currentUser.facebook;
      this.form.instagram = this.currentUser.instagram;
      this.form.twitter = this.currentUser.twitter;
      this.form.linkedin = this.currentUser.linkedin;
      this.form.hideSensitiveData = this.currentUser.hideSensitiveData;
      this.form.hideStats = this.currentUser.hideStats;
      this.form.participateInRankings = this.currentUser.participateInRankings;
    },

    onSubmit() {
      const dateMoment = this.$moment(this.form.birthDate, "DD/MM/YYYY", true);
      const date = dateMoment.format("YYYY-MM-DD");
      const validateDate =
        dateMoment.isValid() && dateMoment.isSameOrBefore(this.$moment());

      if (this.form.birthDate && !validateDate) {
        return this.$swal("Opa", "Você precisa uma data válida!", "warning");
      }

      const payload = {};

      for (let [key, value] of Object.entries(this.currentUser)) {
        const nextValue = this.form[key];

        if (["confirmPassword"].includes(key) || nextValue === value) {
          continue;
        }

        payload[key] = nextValue;
      }

      if (this.form.password) {
        payload.password = this.form.password;
      }

      if (payload.birthDate) {
        payload.birthDate = date;
      }

      if (payload.telephone) {
        payload.telephone = payload.telephone
          .split("")
          .filter((x) => /\d/.test(x))
          .join("");
      }

      return api
        .updateUser(this.currentUser.id, payload)
        .then(() => this.$store.dispatch("auth/validate"))
        .then(() => {
          this.$swal("Sucesso", "Dados atualizados", "success");
          this.resetForm();
          if (this.$route.query.firstTime) {
            return this.$router.push({
              name: "profile",
              params: { username: this.currentUser.username },
              query: { firstTime: 1 },
            });
          }
          return this.$router.push({ name: "dashboard" });
        })
        .catch((error) => {
          let specificError = [];

          if (error.response && error.response.data) {
            if (
              error.response.data.username.some(
                (item) => item.validation === "unique"
              )
            ) {
              specificError.push("Nome de usuário já existe!!");
            }
          }

          if (specificError.length) {
            this.$swal("Opa", specificError.join("<br />"), "error");
            return;
          }

          this.$swal("Opa", "Algo deu errado", "error");
        });
    },

    avatarUploaded(image) {
      return api
        .updateUser(this.currentUser.id, { avatarId: image.id })
        .then(() => this.$store.dispatch("auth/validate"))
        .then(() => {
          this.$swal("Sucesso", "Avatar atualizado", "success");
        })
        .catch(() => {
          this.$swal("Opa", "Algo deu errado", "error");
        });
    },
  },
};
</script>

<style scoped>
.vue-dropzone {
  text-align: center;
}
</style>

<style>
.editing-user #user-vertical-cart-avatar {
  min-width: 256px;
  min-height: 256px;
  height: auto;
  background-color: rgba(12, 12, 12, 0.1);
  max-width: 256px;
  margin: 0 auto;
}
.editing-user #user-vertical-cart-avatar:hover {
  cursor: pointer;
}
.editing-user .cropper-view-box {
  border-radius: 50% !important;
}
</style>
